.title
  margin: 0
  padding: 0
  font-size: 1.7em
  font-weight: 600
  line-height: 1.25 !important
  &-intro
    font-size: 4em
    font-weight: 800
  +v-xs
    font-size: 1.2em
  &-mini
    font-size: 1em
    font-weight: 400
    line-height: 1.45
  &-text
    margin: 16px 0 8px
    font-size: 1.15em
    font-weight: 600
  &-part
    margin: 20px 0
    font-size: 2.55em
    font-weight: 700
    background: #CF074D
    background: linear-gradient(to right, #651ACF 9%, #CF074D 100%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
  &-sub
    width: 70%
    margin: 40px auto
    font-size: 3.35em
    font-weight: 800 !important
    background: #CF074D
    background: linear-gradient(to right, #CF074D 9%, #651ACF 100%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    &.white
      background: none
      -webkit-text-fill-color: $white !important
    &.dark
      background: none
      -webkit-text-fill-color: $dark !important
    &.blue
      background: none
      -webkit-text-fill-color: $blue !important
    &.violet
      background: none
      -webkit-text-fill-color: $violet !important
    &.pink
      background: none
      -webkit-text-fill-color: $pink !important
    &.orange
      background: none
      -webkit-text-fill-color: $orange !important
    &.magenta
      background: none
      -webkit-text-fill-color: $magenta !important
    &.marine
      background: none
      -webkit-text-fill-color: $marine !important
    &.theme
      background: none
      -webkit-text-fill-color: $theme !important
    &.m-0
      margin: 0 !important
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
    &.w-60
      +v-xs
        margin: 0 auto
        min-width: 96% !important
    &.w-40
      +v-sm
        margin: 0 0 60px
        min-width: 100% !important

#top
  .title-intro
    background: #CF074D
    background: linear-gradient(to right, #8ECFFD 0%, #FA77FC 100%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent

footer
  +v-sm
    margin-top: 80px
  a
    &:hover
      color: $dark !important
  .f
    &-text
      color: $footer
      font-size: 1em
      font-weight: 500
      line-height: 1.5

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important
