.card
  padding: 0 !important
  border: none !important
  background-color: transparent !important
  border-radius: 28px !important
  overflow: hidden
  +v-sm
    margin: 40px 40px 0
  +v-xs
    margin: 40px 0 0
    min-width: 100% !important
  &-footer
    display: block
    width: 100%
    border-top: 1px solid $theme
    margin: 0 !important
    border-radius: 0 0 20px 20px !important
    background-color: lighten($theme,15%)
    .button
      border-radius: 24px
      border: none !important
  .list-unstyled
    &.w-50
      +v-xs
        display: block
        min-width: 100% !important
        margin-left: 8px
      &.rt
        +v-xs
          margin-top: -22px !important
    li
      padding: 2px 0
  .info
    .flex
      +v-xs
        display: block
      .card-text
        &.my-0
          &.py-0
            +v-xs
              margin-bottom: 8px !important
      *
        +v-xs
          text-align: center !important

  .flex
    &.flex-list
      +v-xs
        display: block
  *
    font-size: 99% !important
    font-smooth: always
    -webkit-font-smoothing: antialised
    -moz-osx-font-smoothing: grayscale
    +v-md
      font-size: 90% !important
    +v-sm
      font-size: 99% !important


  &-header
    border-bottom: none
    h4
      line-height: 1.15
      font-size: 24px !important
      font-weight: lighter !important
      span
        font-size: 18px !important
  &-title
    font-size: 24px !important
    font-weight: 500 !important
    small
      font-size: 16px !important
  &-subtitle
    margin-top: 4px
    font-size: 22px !important
    font-weight: 300 !important
    small
      font-size: 14px !important
  &-sub
    height: 80px
    margin-top: 4px
    padding: 20px 30px !important
    font-size: 18px !important
    font-weight: 400 !important
    line-height: 1.5
    text-align: center
    border-top: 1px solid lighten($light,8%)
    border-bottom: 1px solid lighten($light,8%)
    small
      font-size: 14px !important
  &-mini
    min-height: 64px
    font-size: 14px !important
    font-weight: 400 !important
    line-height: 1.5
  &-text
    font-size: 16px
    font-weight: 400 !important
    line-height: 1.5
    &.shift
      margin-left: 64px
      margin-right: 32px
      +v-sm
        margin-right: 20px !important
      +v-xs
        margin-left: 24px !important
  &-body
    background-color: $white

.prl
  margin-right: 8px
  &.last
    +v-sm
      margin-left: 1px !important
      margin-right: 8px !important
.grdv
  position: relative
  z-index: 2
  background-color: transparent
  +v-sm
    padding-bottom: 24px
  .flex
    +v-sm
      display: block
    +v-xs
      margin-left: 14px
  .inf
    +v-sm
      margin-left: 64px
      margin-top: -52px
  img
    position: relative
    left: 4px
    z-index: 9
    background-color: #fff
    border-radius: 40px
    border: 4px solid $white
  &-in
    position: absolute
    display: block
    top: 12px
    bottom: 0
    left: 16px
    width: 24px
    border-radius: 12px
    background: linear-gradient(180deg, rgba(144, 90, 150, .8) 41.98%, rgba(224, 85, 115, .8) 100%)
    z-index: 1
    +v-sm
      bottom: 40px
    +v-xs
      background: none
