.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 50px
  padding: 0 56px
  color: $white !important
  font-size: .85em
  font-weight: 400
  letter-spacing: .025em
  text-transform: uppercase
  border-radius: 4px
  transition: all ease-out .4s !important
  background: linear-gradient(73.11deg, #0057FF 0%, #00E4C9 91%)
  &.pink
    background: linear-gradient(73.18deg, #FF1BCE 0%, #E4B200 91%)
  &.tab
    color: $dark !important
    margin: 0 8px
    border: none !important
    outline: none !important
    background: #fff
    border: 1px solid darken($light,6%)
    &.active
      color: $white !important
      background: linear-gradient(73.18deg, #FF1BCE 0%, #E4B200 91%) !important
  &:hover
    color: $white !important
    background: linear-gradient(73.18deg, $theme 0%, $blue 91%) !important

.tabs
  display: inline-block
  padding: 16px
  border-radius: 8px
  background-color: darken($light,2%)

#top
  .button
    +v-sm
      display: inline-flex
      width: 40%
      margin: 0 24px 0 0
    +v-xs
      display: flex
      width: 100%
      margin: 24px 0
