@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')

$body: #fff
$theme: #7832C3
$dark: #302D4E
$white: #fff
$light: #FAFAFA
$medium: #222222
$lite: #BABABA

$button: $theme

$blue: #3A91FC
$violet: #6C469C
$magenta: #FD306D
$pink: #F15BB1
$marine: #08ADC4
$footer: #282828
$grey: #cdcdcd
$orange: #FD9F30

$basic: 'Inter', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
