#top
  width: 100%
  .intro-content
    position: relative
    height: auto
    z-index: 9
  .background
    z-index: 0

.intro-bottom
  position: relative
  z-index: 10
