@keyframes move
  100%
    transform: translate3d(0, 0, 1px) rotate(360deg)

.background
  position: fixed
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  background: #250f43
  background: linear-gradient(127.39deg, #5F0E4D -32.28%, #1E1732 80.32%)
  span
    width: 48vmin
    height: 48vmin
    border-radius: 48vmin
    backface-visibility: hidden
    position: absolute
    animation: move
    animation-duration: 45
    animation-timing-function: linear
    animation-iteration-count: infinite

    &:nth-child(0)
      color: #52115f
      top: 11%
      left: 82%
      animation-duration: 9s
      animation-delay: -22s
      transform-origin: 19vw 18vh
      box-shadow: -96vmin 0 12.602657147695759vmin currentColor

    &:nth-child(1)
      color: #38225d
      top: 53%
      left: 15%
      animation-duration: 27s
      animation-delay: -28s
      transform-origin: -21vw 1vh
      box-shadow: 96vmin 0 12.02186650808254vmin currentColor

    &:nth-child(2)
      color: #52115f
      top: 58%
      left: 49%
      animation-duration: 13s
      animation-delay: -21s
      transform-origin: 19vw 14vh
      box-shadow: -96vmin 0 12.769400578468966vmin currentColor

    &:nth-child(3)
      color: #52115f
      top: 22%
      left: 40%
      animation-duration: 14s
      animation-delay: -48s
      transform-origin: -21vw 23vh
      box-shadow: 96vmin 0 12.09999108004483vmin currentColor

    &:nth-child(4)
      color: #52115f
      top: 24%
      left: 34%
      animation-duration: 7s
      animation-delay: -36s
      transform-origin: 5vw -22vh
      box-shadow: 96vmin 0 12.473892560004435vmin currentColor

    &:nth-child(5)
      color: #38225d
      top: 93%
      left: 53%
      animation-duration: 33s
      animation-delay: -37s
      transform-origin: 14vw 17vh
      box-shadow: -96vmin 0 12.074266912861813vmin currentColor

    &:nth-child(6)
      color: #38225d
      top: 5%
      left: 28%
      animation-duration: 35s
      animation-delay: -5s
      transform-origin: 4vw -4vh
      box-shadow: 96vmin 0 12.544239215715127vmin currentColor

    &:nth-child(7)
      color: #38225d
      top: 72%
      left: 82%
      animation-duration: 47s
      animation-delay: -13s
      transform-origin: 13vw -8vh
      box-shadow: 96vmin 0 12.922384019726195vmin currentColor

    &:nth-child(8)
      color: #38225d
      top: 64%
      left: 55%
      animation-duration: 24s
      animation-delay: -17s
      transform-origin: 12vw 25vh
      box-shadow: -96vmin 0 12.501226753668917vmin currentColor

    &:nth-child(9)
      color: #52115f
      top: 14%
      left: 4%
      animation-duration: 15s
      animation-delay: -38s
      transform-origin: -11vw -12vh
      box-shadow: -96vmin 0 12.825446943814143vmin currentColor

    &:nth-child(10)
      color: #52115f
      top: 99%
      left: 100%
      animation-duration: 53s
      animation-delay: -46s
      transform-origin: -10vw 11vh
      box-shadow: 96vmin 0 12.463010969084213vmin currentColor

    &:nth-child(11)
      color: #52115f
      top: 83%
      left: 68%
      animation-duration: 34s
      animation-delay: -20s
      transform-origin: -9vw 11vh
      box-shadow: -96vmin 0 12.086977473240411vmin currentColor

    &:nth-child(12)
      color: #46154c
      top: 49%
      left: 35%
      animation-duration: 34s
      animation-delay: -46s
      transform-origin: -10vw 25vh
      box-shadow: 96vmin 0 12.497770639720615vmin currentColor

    &:nth-child(13)
      color: #38225d
      top: 81%
      left: 64%
      animation-duration: 32s
      animation-delay: -7s
      transform-origin: 20vw -11vh
      box-shadow: -96vmin 0 12.067167248079379vmin currentColor

    &:nth-child(14)
      color: #38225d
      top: 54%
      left: 13%
      animation-duration: 54s
      animation-delay: -30s
      transform-origin: 16vw 4vh
      box-shadow: 96vmin 0 12.8716039208618vmin currentColor

    &:nth-child(15)
      color: #38225d
      top: 27%
      left: 82%
      animation-duration: 33s
      animation-delay: -17s
      transform-origin: -18vw -11vh
      box-shadow: 96vmin 0 12.136963756705452vmin currentColor

    &:nth-child(16)
      color: #38225d
      top: 34%
      left: 24%
      animation-duration: 13s
      animation-delay: -36s
      transform-origin: 17vw 6vh
      box-shadow: 96vmin 0 12.685966477881738vmin currentColor

    &:nth-child(17)
      color: #46154c
      top: 67%
      left: 44%
      animation-duration: 33s
      animation-delay: -26s
      transform-origin: 3vw -8vh
      box-shadow: 96vmin 0 12.902360586740642vmin currentColor

    &:nth-child(18)
      color: #46154c
      top: 100%
      left: 34%
      animation-duration: 9s
      animation-delay: -47s
      transform-origin: -4vw -14vh
      box-shadow: -96vmin 0 12.353743079951819vmin currentColor

    &:nth-child(19)
      color: #52115f
      top: 19%
      left: 99%
      animation-duration: 26s
      animation-delay: -23s
      transform-origin: -12vw -14vh
      box-shadow: -96vmin 0 12.277911222042304vmin currentColor

.bg
  &-001
    background: #eee url('../../assets/bg001.png') center center no-repeat
    background-size: cover
    background-blend-mode: overlay
  &-002
    background: #eee url('../../assets/bg002.png') center center no-repeat
    background-size: cover
    background-blend-mode: overlay
  &-003
    background: #eee url('../../assets/bg003.png') center center no-repeat
    background-size: cover
    background-blend-mode: overlay
  &-004
    background: #eee url('../../assets/bg004.png') center center no-repeat
    background-size: cover
    background-blend-mode: overlay
  &-005
    background: #eee url('../../assets/bg005.png') center center no-repeat
    background-size: cover
    background-blend-mode: overlay

.card
  &.bg-001,
  &.bg-002,
  &.bg-003,
  &.bg-004,
  &.bg-005
    height: 320px
    position: relative
    box-shadow: 0 3px 24px rgba(0,0,0,.1)
    h3
      position: absolute
      left: 40px
      bottom: 0
      font-size: 24px !important
      font-weight: 700
      text-transform: uppercase
    span
      position: absolute
      left: 40px
      top: 40px
      display: inline-flex
      width: 64px !important
      max-width: 64px !important
      min-width: 64px !important
      height: 64px
  &.c-001,
  &.c-002,
  &.c-003,
  &.c-004,
  &.c-005,
  &.c-006
    position: relative
    width: 98% !important
    margin-right: 2% !important
    padding: 24px 32px !important
    text-align: center
    p
      line-height: 1.35 !important
    .title-text
      font-size: 1.45em !important
      font-weight: 400 !important
      line-height: 1.35 !important
    .title-sub
      font-size: 2em !important
      font-weight: 600 !important
      margin: 16px auto !important
      padding: 0 !important
    .btm
      position: absolute
      text-align: center
      width: 100%
      bottom: 8%
      left: 0
    .icon
      position: absolute
      left: calc(50% - 60px)
      top: 44%
      display: flex
      justify-content: center
      align-items: center
      width: 120px
      height: 120px
      border: 8px solid #ffffff66
      border-radius: 60px
      margin-left: auto
      margin-right: auto
  &.c
    &-001
      background: #eee url('../../assets/c01.png') center center no-repeat
      background-size: cover
    &-002
      background: #eee url('../../assets/c02.png') center center no-repeat
      background-size: cover
    &-003
      background: #eee url('../../assets/c03.png') center center no-repeat
      background-size: cover
    &-004
      background: #eee url('../../assets/c04.png') center center no-repeat
      background-size: cover
    &-005
      background: #eee url('../../assets/c05.png') center center no-repeat
      background-size: cover
    &-006
      background: #eee url('../../assets/c06.png') center center no-repeat
      background-size: cover

.bg
  &-team
    background-color: rgba(255,255,255,.05)
